.datePicker {
  width: 100%;
}
.css-n4mf1r {
  background: #000 !important;
}

.css-1jztrtc.Mui-active {
  color: black;
}

.react-datepicker__input-container input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  padding: 14px 14px;
  /* padding: 16.5px 14px; */
  /* border-color: #cfd0d1; */
  border: 1px solid #cfd0d1;
  border-top-color: rgba(0, 0, 0, 0.12);
  border-left-color: rgba(0, 0, 0, 0.12);
}

.react-datepicker__input-container input:hover {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  /* padding: 16.5px 14px; */
  padding: 14px 14px;
  border-color: rgba(0, 0, 0, 1);
}

.css-1q2db8n-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}

.css-q40g4t-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}

.sub-heading {
  height: 0;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sub-heading span {
  display: inline-block;
  position: relative;
  padding: 0 17px;
  top: -11px;
  font-size: 18px;
  /* color: #058; */
  background-color: #fff;
}

.react-datepicker__header {
  background-color: #151515 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #fff !important;
  font-family: "Sarabun", sans-serif;
}

/* .react-datepicker__day--keyboard-selected{
    border-radius: 0.3rem;
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
} */

.react-datepicker__day--selected {
  background: black !important;
  font-family: "Sarabun", sans-serif;
  color: rgb(255, 255, 255) !important;
}

.react-datepicker__header button {
  background: transparent;
  font-family: "Sarabun", sans-serif;
  color: #fff;
}

.react-datepicker__header select {
  background: black !important;
  color: #fff !important;
}

/* react-datepicker__day react-datepicker__day--012 react-datepicker__day--selected */

.react-datepicker__day--today {
  border-radius: 0.3rem !important;
  background-color: #ebebeb !important;
  color: rgb(0, 0, 0) !important;
  font-family: "Sarabun", sans-serif;
}

.react-datepicker__day--today:hover {
  border-radius: 0.3rem !important;
  background-color: black !important;
  color: rgb(255, 255, 255) !important;
  font-family: "Sarabun", sans-serif;
}

.react-datepicker__day--weekend {
  color: red !important;
  font-family: "Sarabun", sans-serif;
}

.react-datepicker__day {
  font-weight: bold !important;
}

.MuiCardHeader-title {
  font-size: 1.125rem;
  color: white;
}

.MuiCardHeader-root {
  padding: 16px !important;
}

.MuiInputBase-input {
  padding: 15.5px 14px !important;
  background-color: white;
}

.MuiInputBase-inputSizeSmall {
  padding: 10px 14px !important;
  background-color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
}

.react-datepicker__close-icon::after {
  background-color: red;
  /* background-color: #181414; */
  color: #fff;
}

.react-datepicker-wrapper {
  width: 100%;
}

.rc-time-picker {
  width: 100%;
  margin-top: 10px;
  /* padding: 16.5px 14px; */
}
.rc-time-picker-input {
  height: 57px !important;
  font-size: 14px !important;
  color: #000 !important;
  font-family: "Sarabun", sans-serif;
  padding: 16.5px 14px !important;
}

.rc-time-picker-clear {
  top: 12px !important;
  height: 30px !important;
}

.rc-time-picker-clear-icon:after {
  background-color: red !important;
  color: white !important;
  font-family: "Sarabun", sans-serif;
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
}

.rc-time-picker-panel-narrow {
  max-width: 125px !important;
}

.rc-time-picker-panel-select {
  width: 60px !important;
}

.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
  background-color: #fff !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
  background-color: #fff !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
}

.MuiAutocomplete-input {
  background-color: #fff !important;
}

.custom-date-picker {
}

input[type="file"] {
  /* opacity: 0; */
}

.css-if7jzm-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #181414;
}
/* 
input[type="file"] {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
} */

/* .guZdik{
  width: 100% !important;
} */

.guZdik {
  border: 2px dashed rgb(24, 20, 20) !important;
  min-width: 0px !important;
  max-width: none !important;
}

.file-uploader-custom {
  margin-top: 10px !important;
}

.rbc-event {
  background-color: #181414;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Sarabun", sans-serif;
  font-size: 16px;
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

/* .MuiOutlinedInput-root {
  padding: 0 !important;
  margin-top: 10px;
  margin-bottom: 10px;
} */

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
  margin-bottom: 10px;
  margin-top: 10px;
}
